import React, { useState } from 'react';
import { uploadFile } from 'react-s3';
import { Buffer } from 'buffer';
import { values } from 'lodash';
import {
  Box,
  Button,
  Container,
  Input,
  FormLabel,
  Typography,
  TextField,
} from '@mui/material';

window.Buffer = Buffer;

const App = () => {

  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);

  const [form, setForm] = useState({
    bucketName: '',
    dirName: '',
    region: '',
    accessKeyId: '',
    secretAccessKey: ''
  });

  const handleFileInput = (e) => {
    console.log('e.target.files: ', e.target.files)
    setSelectedFile(e.target.files[0]);
  };

  const handleTextField = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleUpload = async () => {
    if (values(form).includes('')) return setError('Input error: All fields are required.');
    if (!selectedFile) return setError('Please choose a file to upload.');
    uploadFile(selectedFile, form)
      .then(data => console.log(data))
      .catch(err => console.error(err));
  };

  return (
    <Container style={{
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      flexGrow: 1
    }}>
      <Typography variant="h2">Upload to S3</Typography>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexGrow: 1,
          width: 480,
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ p: 2, mb: 2 }}>
          <TextField
            fullWidth
            value={form.bucketName || ""}
            onChange={handleTextField}
            required
            name="bucketName"
            label="Bucket Name"
            type="text"
            id="bucketName"
          />
        </Box>
        <Box sx={{ p: 2, mb: 2 }}>
          <TextField
            fullWidth
            value={form.dirName}
            onChange={handleTextField}
            required
            name="dirName"
            label="Bucket Directory"
            type="text"
            id="dirName"
          />
        </Box>
        <Box sx={{ p: 2, mb: 2 }}>
          <TextField
            fullWidth
            value={form.region}
            onChange={handleTextField}
            required
            name="region"
            label="AWS Region"
            type="text"
            id="region"
          />
        </Box>
        <Box sx={{ p: 2, mb: 2 }}>
          <TextField
            fullWidth
            value={form.accessKeyId}
            onChange={handleTextField}
            required
            name="accessKeyId"
            label="AWS Access Key Id"
            type="text"
            id="accessKeyId"
          />
        </Box>
        <Box sx={{ p: 2, mb: 2 }}>
          <TextField
            fullWidth
            value={form.secretAccessKey}
            onChange={handleTextField}
            required
            name="secretAccessKey"
            label="AWS Secret Access Key"
            type="text"
            id="secretAccessKey"
          />
        </Box>
        <Box sx={{ p: 2, mb: 2 }}>
          <FormLabel htmlFor="btn-upload">
            {!selectedFile && (
              <>
                <Input
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={handleFileInput} />
                <Button
                  className="btn-choose"
                  variant="outlined"
                  component="span" >
                  Choose File
                </Button>
              </>
            )}
            <Typography variant="h6">
              {selectedFile ? selectedFile.name : null}

            </Typography>
          </FormLabel>
          {selectedFile ? (
            <div>
              <img src={URL.createObjectURL(selectedFile)} width="150" />
              <Button
                className="btn-choose"
                variant="outlined"
                onClick={() => setSelectedFile(null)}
              >
                Clear
              </Button>
            </div>
          ) : null}


        </Box>
        {error && (
          <Box sx={{ p: 2, mb: 2 }}>
            <Typography component="span" variant="h6" sx={{ color: 'red' }}>{error}</Typography>
          </Box>
        )}

        <Button
          type="submit"
          onClick={handleUpload}
          fullWidth
          variant="contained"
          sx={{
            mt: 3, mb: 2,
            display: 'inline-block',
            color: 'black',
            backgroundColor: '#F5A327',
          }}
        >
          Upload to S3
        </Button>
      </Box>
      <div style={{ flexGrow: 1 }} />
    </Container>
  );
};

export default App;